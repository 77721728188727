export default {
  name: "navigation",
  data: function data() {
    return {
      list: [{
        name: '首页',
        value: 'home'
      }, {
        name: '学会简介',
        value: 'intro',
        list: [{
          name: '学会简介',
          value: 1
        }, {
          name: '内设机构',
          value: 2
        }, {
          name: '现任领导',
          value: 3
        }, {
          name: '大记事',
          value: 4
        }, {
          name: '联系我们',
          value: 5
        }]
      }, {
        name: '学会要闻',
        value: 'news',
        list: [{
          name: '学会动态',
          value: 1
        }, {
          name: '社团管理',
          value: 2
        }, {
          name: '区（市、县）法学会',
          value: 3
        }, {
          name: '时政要闻',
          value: 4
        }]
      }, {
        name: '党建工作',
        value: 'works',
        list: [{
          name: '支部工作',
          value: 1
        }, {
          name: '工会工作',
          value: 2
        } // {name:'学党史，守初心',value:3},
        ]
      }, {
        name: '通知公告',
        value: 'announcement'
      }, {
        name: '会员工作',
        value: 'vipWork',
        list: [{
          name: '会员申请',
          value: 1
        }, {
          name: '留言',
          value: 2
        }]
      }, {
        name: '法律咨询专家库',
        value: 'talent'
      }],
      currentTab: ''
    };
  },
  mounted: function mounted() {
    this.currentTab = this.$route.meta.nav;
  },
  methods: {
    toPage: function toPage(parent, id) {
      if (parent === 'home') {
        this.$router.push({
          path: '/' + parent,
          query: {
            id: id
          }
        });
      } else {
        if (!id && (parent === 'vipWork' || parent === 'works' || parent === 'news' || parent === 'intro')) {
          this.$router.push({
            path: '/mainBox/' + parent,
            query: {
              id: 1
            }
          });
        } else {
          this.$router.push({
            path: '/mainBox/' + parent,
            query: {
              id: id
            }
          });
        }
      }
    }
  },
  watch: {
    $route: {
      handler: function handler() {
        this.currentTab = this.$route.meta.nav;
      },
      deep: true
    }
  }
};
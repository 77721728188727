import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
export default {
  name: "selectComponent",
  props: ['list', 'type', 'currentItemValue'],
  data: function data() {
    return {
      flag: true,
      currentItemName: '不限' // currentItemValue:0,

    };
  },
  mounted: function mounted() {
    var _this = this;

    document.addEventListener('click', function (e) {
      if (!_this.$el.contains(e.target)) {
        _this.flag = true; //这句话的意思是点击其他区域关闭（也可以根据自己需求写触发事件）
      }
    });
    console.log('currentItemValue-----', this.currentItemValue);
    this.list.forEach(function (item) {
      if (item.id == _this.currentItemValue) {
        _this.currentItemName = item.name;
      }
    });
  },
  methods: {
    handleShow: function handleShow() {
      this.flag = !this.flag;
    },
    selectItem: function selectItem(item) {
      this.currentItemName = item.name; // eslint-disable-next-line vue/no-mutating-props

      this.currentItemValue = item.id;
      this.handleShow();
      this.$emit('selectResult', item, this.type);
    }
  }
};
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.function.name.js";
import { sendMessage } from '@/service/index.server';
export default {
  name: "vipWork",
  data: function data() {
    var validate1 = function validate1(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入留言标题'));
      } else {
        callback();
      }
    };

    var validate2 = function validate2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入联系人'));
      } else {
        callback();
      }
    };

    var validate3 = function validate3(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入联系电话'));
      } else if (!/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(value)) {
        callback(new Error('请输入正确的联系电话'));
      } else {
        callback();
      }
    };

    var validate5 = function validate5(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入留言内容'));
      } else {
        callback();
      }
    };

    return {
      list: [],
      currentPage: 1,
      pageName: '学会要闻',
      form: {
        title: '',
        contact: '',
        phone: '',
        qq: '',
        content: ''
      },
      rules: {
        title: [{
          validator: validate1,
          trigger: 'blur',
          required: true
        }],
        contact: [{
          validator: validate2,
          trigger: 'blur',
          required: true
        }],
        phone: [{
          validator: validate3,
          trigger: 'blur',
          required: true
        }],
        content: [{
          validator: validate5,
          trigger: 'blur',
          required: true
        }]
      }
    };
  },
  mounted: function mounted() {
    this.list = this.$route.meta.menu;
    this.checkoutSelected();
  },
  methods: {
    //
    jumpTo: function jumpTo() {
      // window.open('https://hyxt.chinalaw.org.cn/bi_member_login/loginAction.do');
      window.open('https://hyxt.chinalaw.org.cn/account/personlogin');
    },
    handleNews: function handleNews(id) {
      if (id) {
        this.$router.push({
          query: {
            id: id
          }
        });
      }
    },
    checkoutSelected: function checkoutSelected() {
      if (this.$route.query.id) {
        this.currentPage = parseInt(this.$route.query.id);

        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].value === this.currentPage) {
            this.pageName = this.list[i].name;
          }
        }
      }
    },
    submitForm: function submitForm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          sendMessage(_this.form).then(function (res) {
            if (res.code === '1') {
              _this.form = {
                title: '',
                contact: '',
                phone: '',
                qq: '',
                content: ''
              };
            }
          });
        }
      });
    }
  },
  watch: {
    $route: {
      handler: function handler() {
        this.checkoutSelected();
      },
      deep: true
    }
  }
};
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import { getMessageList } from '@/service/index.server';
export default {
  name: "news",
  data: function data() {
    return {
      list: [],
      currentPage: 1,
      pageName: '学会要闻',
      contentList: [],
      page: 1,
      pageSize: 10,
      count: 0
    };
  },
  mounted: function mounted() {
    this.list = this.$route.meta.menu;
    this.checkoutSelected(); // 1学会动态,2法治新闻,3区县法学,4学术委员会,

    if (this.$route.query.id) {
      this.getList(this.$route.query.id);
    }
  },
  methods: {
    pageChange: function pageChange(val) {
      this.page = val;
      this.getList(this.$route.query.id);
    },
    handleToDetail: function handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/newsDetail',
        query: {
          key: item.id
        }
      });
    },
    getList: function getList(type) {
      var _this = this;

      getMessageList({
        page: this.page,
        pageSize: this.pageSize,
        type: type
      }).then(function (res) {
        _this.contentList = res.data;
        _this.count = res.count;
      });
    },
    handleNews: function handleNews(id) {
      if (id) {
        this.$router.push({
          query: {
            id: id
          }
        });
      }
    },
    checkoutSelected: function checkoutSelected() {
      if (this.$route.query.id) {
        this.currentPage = parseInt(this.$route.query.id);

        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].value === this.currentPage) {
            this.pageName = this.list[i].name;
          }
        }
      }
    },
    listItemMonths: function listItemMonths(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate: function listItemDate(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    getText: function getText(str) {
      if (str) {
        return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
      } else {
        return '';
      }
    }
  },
  watch: {
    $route: {
      handler: function handler() {
        this.checkoutSelected();

        if (this.$route.query.id) {
          this.page = 1;
          this.getList(this.$route.query.id);
        }
      },
      deep: true
    }
  }
};
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import { getSearchResult as _getSearchResult } from '@/service/index.server';
export default {
  name: "searchList",
  data: function data() {
    return {
      pageName: '',
      contentList: [],
      pageSize: 10,
      page: 1,
      keyword: '',
      count: 0
    };
  },
  mounted: function mounted() {
    this.pageName = this.$route.name;
    this.keyword = this.$route.query.keyword;
    this.getSearchResult();
  },
  methods: {
    pageChange: function pageChange(val) {
      this.page = val;
      this.getSearchResult();
    },
    listItemMonths: function listItemMonths(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate: function listItemDate(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    getSearchResult: function getSearchResult() {
      var _this = this;

      _getSearchResult({
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword
      }).then(function (res) {
        _this.contentList = res.data;
        _this.count = res.count;
      });
    },
    handleToDetail: function handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/newsDetail',
        query: {
          key: item.id,
          enter: 'searchList'
        }
      });
    },
    getText: function getText(str) {
      return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
    }
  },
  watch: {
    $route: {
      handler: function handler() {
        if (this.$route.query.keyword) {
          this.keyword = this.$route.query.keyword;
          this.page = 1;
          this.getSearchResult();
        }
      },
      deep: true
    }
  }
};
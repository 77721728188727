import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import { getIntroList, getLeaderList } from '@/service/index.server';
export default {
  name: "intro",
  data: function data() {
    return {
      list: [],
      currentPage: 1,
      pageName: '单位简介',
      responseData: {
        title: '111111111111111111',
        content: ''
      },
      leaderTitle: '',
      leasers: {},
      keyArray: []
    };
  },
  mounted: function mounted() {
    this.list = this.$route.meta.menu;
    this.checkoutSelected();

    if (parseInt(this.$route.query.id) === 3) {
      this.getLeader();
    } else {
      if (parseInt(this.$route.query.id) === 5) {
        this.getList(4);
      } else if (parseInt(this.$route.query.id) === 4) {
        this.getList(3);
      } else {
        this.getList(this.$route.query.id);
      }
    }
  },
  methods: {
    getList: function getList(type) {
      var _this = this;

      getIntroList({
        type: type
      }).then(function (res) {
        if (res.data) {
          _this.responseData = res.data;
        }
      });
    },
    getLeader: function getLeader() {
      var _this2 = this;

      getLeaderList().then(function (res) {
        _this2.leaderTitle = res.data.title;
        var list = res.data.lawUnitLeadershipList;
        var obj = {};

        for (var i = 0; i < list.length; i++) {
          if (obj[list[i].typeId]) {
            obj[list[i].typeId].list.push(list[i]);
          } else {
            obj[list[i].typeId] = {};
            obj[list[i].typeId].title = list[i].typeName;
            obj[list[i].typeId].list = [list[i]];
          }
        }

        _this2.leasers = obj;
        console.log(obj);
        _this2.keyArray = Object.keys(obj);
      });
    },
    handleIntro: function handleIntro(id) {
      if (id) {
        this.$router.push({
          query: {
            id: id
          }
        });
      }
    },
    checkoutSelected: function checkoutSelected() {
      if (this.$route.query.id) {
        this.currentPage = parseInt(this.$route.query.id);

        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].value === this.currentPage) {
            this.pageName = this.list[i].name;
          }
        }
      }
    },
    handleFlag: function handleFlag(index) {
      return index === this.keyArray[0];
    }
  },
  watch: {
    $route: {
      handler: function handler() {
        this.checkoutSelected();

        if (this.$route.query.id) {
          if (parseInt(this.$route.query.id) === 3) {
            this.getLeader();
          } else {
            if (parseInt(this.$route.query.id) === 5) {
              this.getList(4);
            } else if (parseInt(this.$route.query.id) === 4) {
              this.getList(3);
            } else {
              this.getList(this.$route.query.id);
            }
          }
        }
      },
      deep: true
    }
  }
};
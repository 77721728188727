import "core-js/modules/es.function.name.js";
import { getNewsDetail } from '@/service/index.server';
export default {
  components: {},
  name: "newsDetail",
  data: function data() {
    return {
      pageName: '',
      detail: {},
      last: {},
      next: {},
      flag: 1,
      type: ''
    };
  },
  mounted: function mounted() {
    this.getDetail(this.$route.query.key); // if(this.$route.query.enter && this.$route.query.enter === 'searchList') {
    //   this.flag = 0;
    // }

    this.type = this.$route.query.type;
    this.pageName = this.$route.name;
  },
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;

      getNewsDetail({
        id: id
      }).then(function (res) {
        _this.detail = res.data.this;

        if (res.data.last) {
          _this.last = res.data.last;
        } else {
          _this.last = {};
        }

        if (res.data.next) {
          _this.next = res.data.next;
        } else {
          _this.next = {};
        }
      });
    },
    lookOther: function lookOther(item) {
      this.getDetail(item.id);
      document.body.scrollTop = 0; // firefox

      document.documentElement.scrollTop = 0; // safari

      window.pageYOffset = 0;
    },
    showTopText: function showTopText() {
      if (this.$route.query.enter === 'searchList') {
        return '搜索列表>';
      } else if (this.$route.query.enter === 'home') {
        return null;
      } else if (this.$route.query.enter === 'list') {
        if (parseInt(this.type) === 8) {
          return '法学研究>';
        } else if (parseInt(this.type) === 9) {
          return '法治宣传>';
        } else if (parseInt(this.type) === 10) {
          return '法律服务>';
        } else {
          return '头条新闻>';
        }
      } else {
        return '学会要闻>';
      }
    }
  }
};
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import { getMessageList, getHeadlinesList } from '@/service/index.server';
export default {
  name: "searchList",
  data: function data() {
    return {
      pageName: '',
      contentList: [],
      pageSize: 10,
      page: 1,
      keyword: '',
      count: 0,
      type: 7
    };
  },
  mounted: function mounted() {
    this.type = this.$route.query.type;
    var title = this.$route.name;

    if (parseInt(this.type) === 8) {
      title = '法学研究';
    } else if (parseInt(this.type) === 9) {
      title = '法治宣传';
    } else if (parseInt(this.type) === 10) {
      title = '法律服务';
    }

    this.pageName = title;

    if (this.type) {
      this.getSearchResult();
    } else {
      this.getList();
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;

      getHeadlinesList({
        page: this.page,
        pageSize: this.pageSize
      }).then(function (res) {
        _this.contentList = res.data;
        _this.count = res.count;
      });
    },
    pageChange: function pageChange(val) {
      console.log(val, this.type);
      this.page = val;

      if (this.type) {
        this.getSearchResult();
      } else {
        this.getList();
      }
    },
    listItemMonths: function listItemMonths(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate: function listItemDate(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    getSearchResult: function getSearchResult() {
      var _this2 = this;

      getMessageList({
        page: this.page,
        pageSize: this.pageSize,
        type: this.type
      }).then(function (res) {
        _this2.contentList = res.data;
        _this2.count = res.count;
      });
    },
    handleToDetail: function handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/newsDetail',
        query: {
          key: item.newsId || item.id,
          enter: 'list',
          type: this.type
        }
      });
    },
    getText: function getText(str) {
      if (str) {
        return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
      }
    }
  }
};
// 1. 从vue-router 中按需导入两个方法
// 2. createRouter 方法用于创建路由的实例对象
// 3. createHashHistory 用于指定路由的工作模式（hash 模式）
import {
  createRouter,
  createWebHashHistory,
  // createWebHistory
} from 'vue-router';
import Home from '@/views/home.vue';
import mainBox from '@/views/mainBox.vue';

import intro from '@/views/intro';
import news from '@/views/news';
import works from '@/views/works';
import announcement from '@/views/announcement';
import vipWork from '@/views/vipWork';
import talent from '@/views/talent';
import talentDetail from '@/views/talentDetail';
import newsDetail from '@/views/newsDetail';
import announcementDetail from '@/views/announcementDetail';
import searchList from '@/views/searchList';
import headlinesDetail from '@/views/headlinesDetail';
import list from '@/views/list';
export default createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Home,
      meta:{
        nav:'home'
      }
    },
    {
      path: '/home',
      component: Home,
      meta:{
        nav:'home'
      }
    },
    {
      path: '/mainBox',
      component: mainBox,
      children: [
        {
          path: 'intro',
          component: intro,
          name: '单位简介',
          meta: {
            menu: [
              {name: '学会概况'},
              {name: '单位简介',value:1},
              {name: '内设机构',value:2},
              {name: '现任领导',value:3},
              {name: '大 记 事',value:4},
              {name: '联系我们',value:5},
              ],
            nav:'intro'
          }
        },
        {
          path: 'news',
          component: news,
          name: '学会要闻',
          meta: {
            menu: [
              {name: '学会要闻'},
              {name:'学会动态',value:1},
              {name:'社团管理',value:2},
              {name:'区（市、县）法学会',value:3},
              {name:'学术委员会',value:4},
              ],
            nav:'news'
          }
        },
        {
          path: 'works',
          component: works,
          name: '党建工作',
          meta: {
            menu: [
              {name: '党建工作'},
              {name:'支部工作',value:1},
              {name:'工会工作',value:2},
              // {name:'学党史，守初心',value:3},
              ],
            nav:'works'
          }
        },
        {
          path: 'announcement',
          component: announcement,
          name: '通知公告',
          meta: {
            nav:'announcement'
          }
        },
        {
          path: 'vipWork',
          component: vipWork,
          name: '会员工作',
          meta: {
            menu: [
              {name:'会员工作'},
              {name:'会员申请',value:1},
              {name:'留言',value:2},
            ],
            nav:'vipWork'
          }
        },
        {
          path: 'talent',
          component: talent,
          name: '法律咨询专家库',
          meta: {
            nav:'talent'
          },
        },
        {
          path: 'talentDetail',
          component: talentDetail,
          name: '人才详情',
          meta: {
            nav:'talent'
          }
        },
        {
          path: 'newsDetail',
          component: newsDetail,
          name: '正文',
          meta: {

          }
        },
        {
          path: 'announcementDetail',
          component: announcementDetail,
          name: '公告详情',
          meta: {
            nav:'announcement'
          }
        },
        {
          path: 'searchList',
          component: searchList,
          name: '搜索结果',
          meta: {

          }
        },
        {
          path: 'headlinesDetail',
          component: headlinesDetail,
          name: '头条正文',
          meta: {

          }
        },
        {
          path: 'list',
          component: list,
          name: '头条新闻',
          meta: {

          }
        },
      ]
    }
  ]
});

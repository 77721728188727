import "core-js/modules/es.function.name.js";
import { getHeadlinesDetail } from '@/service/index.server';
export default {
  components: {},
  name: "newsDetail",
  data: function data() {
    return {
      pageName: '',
      detail: {},
      last: {},
      next: {},
      flag: 1
    };
  },
  mounted: function mounted() {
    this.pageName = this.$route.name;
    this.getDetail(this.$route.query.key);
  },
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;

      getHeadlinesDetail({
        id: id
      }).then(function (res) {
        _this.detail = res.data.this;
      });
    },
    showTopText: function showTopText() {
      if (this.$route.query.enter === 'home') {
        return null;
      } else {
        return '头条新闻>';
      }
    }
  }
};
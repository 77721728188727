import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { ref } from "vue";
var locale = ref(zhCn);
import { getBanner as _getBanner } from '@/service/index.server';
import navigation from '@/components/navigation';
export default {
  name: "mainBox",
  components: {
    navigation: navigation
  },
  data: function data() {
    return {
      keyword: '',
      locale: locale,
      banner: require("../assets/top-bg.png")
    };
  },
  mounted: function mounted() {
    this.getBanner();
  },
  methods: {
    getBanner: function getBanner() {
      var _this = this;

      _getBanner({
        type: 2
      }).then(function (res) {
        if (res.data && res.data.imageUrl) {
          _this.banner = res.data.imageUrl;
        }
      });
    },
    handleSearch: function handleSearch() {
      if (this.keyword) {
        this.$router.push({
          path: '/mainBox/searchList',
          query: {
            keyword: this.keyword
          }
        });
      }
    }
  }
};
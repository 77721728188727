import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import { getMessageList } from '@/service/index.server';
export default {
  name: "announcement",
  data: function data() {
    return {
      pageName: '',
      contentList: [],
      pageSize: 10,
      page: 1,
      count: 0
    };
  },
  mounted: function mounted() {
    this.pageName = this.$route.name;
    this.getList();
  },
  methods: {
    // back(){
    //   this.$router.push('/');
    //   // this.$router.back(val);
    // },
    pageChange: function pageChange(val) {
      this.page = val;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      getMessageList({
        pageSize: this.pageSize,
        page: this.page,
        type: 11
      }).then(function (res) {
        _this.contentList = res.data;
        _this.count = res.count;
      });
    },
    listItemMonths: function listItemMonths(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate: function listItemDate(val) {
      if (val) {
        var arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    handleToDetail: function handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/announcementDetail',
        query: {
          id: item.id
        }
      });
    },
    getText: function getText(str) {
      return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
    }
  }
};
import "core-js/modules/es.function.name.js";
import { getNewsDetail } from '@/service/index.server';
export default {
  name: "announcementDetail",
  data: function data() {
    return {
      pageName: '',
      detail: {},
      last: {},
      next: {},
      flag: 1
    };
  },
  mounted: function mounted() {
    this.pageName = this.$route.name;
    this.getDetail(this.$route.query.id);
  },
  methods: {
    getDetail: function getDetail(id) {
      var _this = this;

      getNewsDetail({
        id: id
      }).then(function (res) {
        _this.detail = res.data.this;

        if (res.data.last) {
          _this.last = res.data.last;
        } else {
          _this.last = {};
        }

        if (res.data.next) {
          _this.next = res.data.next;
        } else {
          _this.next = {};
        }
      });
    },
    lookOther: function lookOther(item) {
      this.getDetail(item.id);
      document.body.scrollTop = 0; // firefox

      document.documentElement.scrollTop = 0; // safari

      window.pageYOffset = 0;
    }
  }
};
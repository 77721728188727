import "core-js/modules/es.function.name.js";
import { getLawyerInfo } from '@/service/index.server';
export default {
  components: {},
  name: "talentDetail",
  data: function data() {
    return {
      pageName: '',
      detail: {
        researchDirection: ''
      }
    };
  },
  mounted: function mounted() {
    this.pageName = this.$route.name;
    this.$route.query.id && this.getInfo(this.$route.query.id);
  },
  methods: {
    yearFormat: function yearFormat(val) {
      var d1 = new Date(val).getTime();
      var d2 = new Date().getTime();
      var time = Math.round((d2 - d1) / 1000 / 60 / 60 / 24 / 365);
      var string = "\u6301\u8BC1\u4ECE\u4E1A".concat(time, "\u5E74");
      return time ? string : '';
    },
    selectResult: function selectResult(item, type) {
      console.log(item, type);
    },
    getInfo: function getInfo(id) {
      var _this = this;

      getLawyerInfo({
        id: id
      }).then(function (res) {
        console.log(res);
        _this.detail = res.data;
      });
    },
    typeFilter: function typeFilter(val) {
      if (val === 1) {
        return '律师';
      } else if (val === 2) {
        return '专家';
      } else if (val === 3) {
        return '人才';
      }
    }
  }
};
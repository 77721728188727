import "core-js/modules/es.function.name.js";
import selectComponent from '@/components/selectComponent';
import { getLabelList as _getLabelList, getLawyerList as _getLawyerList } from '@/service/index.server';
export default {
  components: {
    selectComponent: selectComponent
  },
  name: "talent",
  data: function data() {
    return {
      pageName: '',
      contentList: [],
      selectList1: [],
      selectList2: [{
        name: '律师',
        id: 1
      }, {
        name: '专家',
        id: 2
      }],
      page: 1,
      pageSize: 5,
      labelId: 0,
      type: 1,
      count: 0
    };
  },
  mounted: function mounted() {
    this.pageName = this.$route.name;
    this.getLabelList();
    this.getLawyerList();
  },
  methods: {
    pageChange: function pageChange(val) {
      this.page = val;
      this.getLawyerList();
    },
    selectResult: function selectResult(item, type) {
      if (type === '1') {
        this.labelId = item.id;
      } else if (type === '2') {
        this.type = item.id;
      }

      this.page = 1;
      this.getLawyerList();
    },
    toDetail: function toDetail(id) {
      this.$router.push({
        path: '/mainBox/talentDetail',
        query: {
          id: id
        }
      });
    },
    getLabelList: function getLabelList() {
      var _this = this;

      _getLabelList().then(function (res) {
        _this.selectList1 = res.data;
      });
    },
    getLawyerList: function getLawyerList() {
      var _this2 = this;

      _getLawyerList({
        page: this.page,
        pageSize: this.pageSize,
        labelId: this.labelId,
        type: this.type
      }).then(function (res) {
        _this2.contentList = res.data;
        _this2.count = res.count;
      });
    },
    yearFormat: function yearFormat(val) {
      var d1 = new Date(val).getTime();
      var d2 = new Date().getTime();
      var time = Math.round((d2 - d1) / 1000 / 60 / 60 / 24 / 365);
      var string = "\u6301\u8BC1\u4ECE\u4E1A".concat(time, "\u5E74");
      return time ? string : '';
    }
  }
};